import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { DateTime } from "luxon";

import { i18nConfig } from "../../i18nConfig";

import { TrimbleLogo } from "../../assets/logos/TrimbleLogo";
import { PrivacyOptionsIcon } from "../../assets/icons/PrivacyOptionsIcon";
import { CookiePreferences } from "../cookie-dialog/CookiePreferences";

import { FacebookIcon } from "../../assets/icons/FacebookIcon";
import { TwitterIcon } from "../../assets/icons/TwitterIcon";
import { LinkedInIcon } from "../../assets/icons/LinkedInIcon";
import { YoutubeIcon } from "../../assets/icons/YoutubeIcon";
import { ChevronRight } from "../../assets/icons/ChevronRight";
import { FooterLink } from "./FooterLink";

const FooterBottom = ({ canUseSiteBeforeAction }: { canUseSiteBeforeAction?: boolean }) => {
  const footerBottomLinks = [
    { link: "legalTermsAndConditions" },
    { link: "trimbleTermsOfUse" },
    { link: "trustPortal" },
    { link: "privacy" },
    {
      link: "yourPrivacyChoices",
      icon: <PrivacyOptionsIcon />,
    },
    { link: "noticeAtCollection" },
  ];

  return (
    <div className="footer-bottom" data-testid="footerBottom">
      <div className="footer-content">
        <div className="trimble-logo">
          <TrimbleLogo />
          <p className="mini" data-testid="copyrightInfo">
            <FormattedMessage
              id="footer.copyrightInfo"
              defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["footer.copyrightInfo"]}
              values={{ year: DateTime.now().year }}
            />
          </p>
        </div>
        <div className="footer-description" data-testid="footerDescription">
          <p>
            <FormattedMessage
              id="footer.description.part1"
              defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["footer.description.part1"]}
            />
          </p>
          <p>
            <FormattedMessage
              id="footer.description.part2"
              defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["footer.description.part2"]}
            />
          </p>
          <span>
            <FooterLink link="trimble" />
          </span>
        </div>
        <div className="links" data-testid="legalLinks">
          {footerBottomLinks.map((obj: { link: string; icon?: React.JSX.Element }, i: number) => (
            <p key={i}>
              <FooterLink link={obj.link} icon={obj.icon} />
            </p>
          ))}
          <p>
            <CookiePreferences canUseSiteBeforeAction={canUseSiteBeforeAction} />
          </p>
        </div>
      </div>
    </div>
  );
};

const SocialIcons = () => {
  const intl = useIntl();

  return (
    <div className="social-icons" data-testid="socialLinks">
      <a
        href={intl.formatMessage({
          id: "footer.socialLinks.facebook",
          defaultMessage: i18nConfig.messages[i18nConfig.defaultLocale][`footer.socialLinks.facebook`],
        })}
        target="_blank"
        rel="noreferrer"
        title="Facebook"
        aria-label="Facebook"
        data-testid="facebook-link"
      >
        <FacebookIcon />
      </a>
      <a
        href={intl.formatMessage({
          id: "footer.socialLinks.twitter",
          defaultMessage: i18nConfig.messages[i18nConfig.defaultLocale][`footer.socialLinks.twitter`],
        })}
        target="_blank"
        rel="noreferrer"
        title="X"
        data-testid="twitter-link"
      >
        <TwitterIcon />
      </a>
      <a
        href={intl.formatMessage({
          id: "footer.socialLinks.linkedin",
          defaultMessage: i18nConfig.messages[i18nConfig.defaultLocale][`footer.socialLinks.linkedin`],
        })}
        target="_blank"
        rel="noreferrer"
        title="LinkedIn"
        aria-label="LinkedIn"
        data-testid="linkedin-link"
      >
        <LinkedInIcon />
      </a>
      <a
        href={intl.formatMessage({
          id: "footer.socialLinks.youtube",
          defaultMessage: i18nConfig.messages[i18nConfig.defaultLocale][`footer.socialLinks.youtube`],
        })}
        target="_blank"
        rel="noreferrer"
        title="Youtube"
        aria-label="Youtube"
        data-testid="youtube-link"
      >
        <YoutubeIcon />
      </a>
    </div>
  );
};

const LinksWithTitles = () => {
  const companyLinks = ["teklaAndTrimble", "officesAndResellers", "careers", "trimbleConstruction"];
  const exploreLinks = ["products", "solutions", "resources", "support"];
  const serviceAvailabilityLinks = ["teklaStatus", "warehouseStatus"];

  return (
    <div className="links-with-titles" data-testid="linksWithTitles">
      <div>
        <h2 className="all-caps">
          <FormattedMessage
            id="footer.ourCompany"
            defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["footer.ourCompany"]}
          />
        </h2>
        {companyLinks.map((link: string, i: number) => (
          <p key={i}>
            <FooterLink link={link} />
          </p>
        ))}
      </div>
      <div>
        <h2 className="all-caps">
          <FormattedMessage
            id="footer.explore"
            defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["footer.explore"]}
          />
        </h2>
        {exploreLinks.map((link: string, i: number) => (
          <p key={i}>
            <FooterLink link={link} />
          </p>
        ))}
      </div>
      <div>
        <h2 className="all-caps">
          <FormattedMessage
            id="footer.serviceAvailability"
            defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["footer.serviceAvailability"]}
          />
        </h2>
        {serviceAvailabilityLinks.map((link: string, i: number) => (
          <p key={i}>
            <FooterLink link={link} />
          </p>
        ))}
      </div>
    </div>
  );
};

const ContactAndSupport = () => {
  const intl = useIntl();

  return (
    <div className="contact-and-support" data-testid="contactAndSupport">
      <div className="contact-us">
        <h2>
          <FooterLink link="contactUs" icon={<ChevronRight />} />
        </h2>
        <p className="mini">
          <FormattedMessage
            id="footer.links.contactUs.description"
            defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["footer.links.contactUs.description"]}
          />
        </p>
      </div>
      <div className="support-and-feedback">
        <h2>
          <a
            href={intl.formatMessage({ id: `footer.links.supportAndFeedback.url` })}
            target="_blank"
            rel="noreferrer"
            data-testid={`supportAndFeedback-link`}
          >
            <FormattedMessage
              id={`footer.links.supportAndFeedback.title1`}
              defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["footer.links.supportAndFeedback.title1"]}
            />{" "}
            <br />
            <FormattedMessage
              id={`footer.links.supportAndFeedback.title2`}
              defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["footer.links.supportAndFeedback.title2"]}
              values={{ icon: <ChevronRight /> }}
            />
          </a>
        </h2>
        <p className="mini">
          <FormattedMessage
            id="footer.links.supportAndFeedback.description"
            defaultMessage={
              i18nConfig.messages[i18nConfig.defaultLocale]["footer.links.supportAndFeedback.description"]
            }
          />
        </p>
      </div>
    </div>
  );
};

const FooterTop = () => {
  return (
    <div className="footer-top" data-testid="footerTop">
      <div className="footer-content">
        <ContactAndSupport />
        <LinksWithTitles />
        <SocialIcons />
      </div>
    </div>
  );
};

/**
 * Component that renders the "Tekla footer"
 */
export const TeklaFooter = ({ canUseSiteBeforeAction }: { canUseSiteBeforeAction?: boolean }) => {
  return (
    <div data-testid="footerContent" data-footer-version="tekla">
      <FooterTop />
      <FooterBottom canUseSiteBeforeAction={canUseSiteBeforeAction} />
    </div>
  );
};
