import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { DateTime } from "luxon";

import { TrimbleLogo } from "../../assets/logos/TrimbleLogo";
import { PrivacyOptionsIcon } from "../../assets/icons/PrivacyOptionsIcon";
import { CookiePreferences } from "../cookie-dialog/CookiePreferences";
import { i18nConfig } from "../../i18nConfig";
import { FooterLink } from "./FooterLink";
import { YoutubeIcon } from "../../assets/icons/YoutubeIcon";
import { LinkedInIcon } from "../../assets/icons/LinkedInIcon";
import { TwitterIcon } from "../../assets/icons/TwitterIcon";
import { FacebookIcon } from "../../assets/icons/FacebookIcon";

const SocialIcons = () => {
  const intl = useIntl();

  return (
    <div className="social-icons" data-testid="socialLinks">

      <a
        href={intl.formatMessage({
          id: "footer.socialLinks.trimble.facebook",
          defaultMessage: i18nConfig.messages[i18nConfig.defaultLocale][`footer.socialLinks.trimble.facebook`],
        })}
        target="_blank"
        rel="noreferrer"
        title="Facebook"
        aria-label="Facebook"
        data-testid="facebook-link"
      >
        <FacebookIcon />
      </a>
      <a
        href={intl.formatMessage({
          id: "footer.socialLinks.trimble.twitter",
          defaultMessage: i18nConfig.messages[i18nConfig.defaultLocale][`footer.socialLinks.trimble.twitter`],
        })}
        target="_blank"
        rel="noreferrer"
        title="X"
        data-testid="twitter-link"
      >
        <TwitterIcon />
      </a>
      <a
        href={intl.formatMessage({
          id: "footer.socialLinks.trimble.linkedin",
          defaultMessage: i18nConfig.messages[i18nConfig.defaultLocale][`footer.socialLinks.trimble.linkedin`],
        })}
        target="_blank"
        rel="noreferrer"
        title="LinkedIn"
        aria-label="LinkedIn"
        data-testid="linkedin-link"
      >
        <LinkedInIcon />
      </a>
      <a
        href={intl.formatMessage({
          id: "footer.socialLinks.trimble.youtube",
          defaultMessage: i18nConfig.messages[i18nConfig.defaultLocale][`footer.socialLinks.trimble.youtube`],
        })}
        target="_blank"
        rel="noreferrer"
        title="Youtube"
        aria-label="Youtube"
        data-testid="youtube-link"
      >
        <YoutubeIcon />
      </a>
    </div>
  );
};

/**
 * Component that renders the footer
 */
export const Footer = ({ canUseSiteBeforeAction }: { canUseSiteBeforeAction?: boolean }) => {
  const footerBottomLinks = [
    { link: "legalTermsAndConditions" },
    { link: "trimbleTermsOfUse" },
    { link: "trustPortal" },
    { link: "privacy" },
    {
      link: "yourPrivacyChoices",
      icon: <PrivacyOptionsIcon />,
    },
    { link: "noticeAtCollection" },
  ];

  return (
    <div className="footer-content" data-testid="footerContent" data-footer-version="common">
      <div className="footer-top">
        <h2 className="all-caps">
          <FormattedMessage
            id="footer.socialLinks"
            defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["footer.socialLinks"]}
          />
        </h2>
        <SocialIcons />
      </div>
      <div className="footer-bottom">
        <div className="trimble-logo">
          <TrimbleLogo />
          <p className="mini" data-testid="copyrightInfo">
            <FormattedMessage
              id="footer.copyrightInfo"
              defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["footer.copyrightInfo"]}
              values={{ year: DateTime.now().year }}
            />
          </p>
        </div>
        <div className="footer-description" data-testid="footerDescription">
          <p>
            <FormattedMessage
              id="footer.description.part2"
              defaultMessage={i18nConfig.messages[i18nConfig.defaultLocale]["footer.description.part2"]}
            />
          </p>
          <span>
            <FooterLink link="trimble" />
          </span>
        </div>
        <div className="links" data-testid="legalLinks">
          {footerBottomLinks.map((obj: { link: string; icon?: React.JSX.Element }, i: number) => (
            <p key={i}>
              <FooterLink link={obj.link} icon={obj.icon} />
            </p>
          ))}
          <p>
            <CookiePreferences canUseSiteBeforeAction={canUseSiteBeforeAction} />
          </p>
        </div>
      </div>
    </div>
  );
};
