import React from "react";

import { Footer } from "./footer/Footer";
import { TeklaFooter } from "./footer/TeklaFooter";

/**
 * Component that renders the footer
 */
export const App = ({ ...props }: {
  canUseSiteBeforeAction?: boolean;
  useTeklaFooter?: boolean;
}) => {
  return (
    <div className="footer-base" data-testid="teklaFooterBase">
      {props.useTeklaFooter ?
        <TeklaFooter canUseSiteBeforeAction={props.canUseSiteBeforeAction} /> :
        <Footer canUseSiteBeforeAction={props.canUseSiteBeforeAction} />
      }
    </div>
  );
};
